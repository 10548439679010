import React from "react";
import {DataGridPremium,} from "@mui/x-data-grid-premium";
import {topNColumns} from "./TopNTableHelper";
import {topNTableStyle} from "./TopNTableStyle";
import {tablesStyle} from "../TablesStyle";
import {ColumnEditIcon, columnLocalText, TableToolbar} from "../TableToolbar/TableToolbar";
import { getNav } from "../TablesHelper";
import { RenderDCADNavigation } from "../RenderCell/RenderDCADNavigation/RenderDCADNavigation";
import { CustomNoRowsOverlay } from "../CustomNoRowsOverlay/CustomNoRowsOverlay"

export const TopNTable = ({topNData, columnVisibilityModel, setColumnVisibilityModel, dateRange, department}) => {
	const accessor = {
		type: "contentSubtype", contentId: "contentId",  dateRange: dateRange
	}
	
	return (
		<DataGridPremium
			sortingOrder={["asc", "desc"]}
			columns={[{
				field: "Navigation",
				headerName: "",
				flex:1,
				valueGetter: getNav.bind(accessor),
				renderCell: RenderDCADNavigation,
				editable: false,
				disableColumnMenu: true,
				disableExport:true
			  },...topNColumns]}
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={(newModel) =>
				setColumnVisibilityModel(newModel)
			}
			rows={topNData}
			components={{
				ColumnSelectorIcon: ColumnEditIcon, Toolbar: TableToolbar, noRowsOverlay: CustomNoRowsOverlay
			}}
			componentsProps={{
				panel: {sx: tablesStyle.toolbar, placement: "bottom-end"},
				toolbar: {table: "TopContentPerformers", dateRange: dateRange, department: department},
				
			}}
			localeText={{toolbarColumns: columnLocalText}}
			initialState={{
				pagination: {
					paginationModel: {pageSize: 10, page: 0},
				},
			}}
			pageSizeOptions={[5, 10, 20]}
			pagination
			columnHeaderHeight={38}
			rowHeight={76}
			autoHeight={true}
			disableRowSelectionOnClick={true}
			sx={topNTableStyle.dataGrid}
		/>
	);
}