import React from "react";
import { Button } from "@mui/material";
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport,} from "@mui/x-data-grid-premium";
import {MoreVert} from "@mui/icons-material";
import {tableToolbarStyle} from "./TableToolbarStyle";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { exportPDF } from "./TableToolbarHelper";

export const TableToolbar = ({table, contentId, dateRange, department, augmentedAnalyticsData, tableData, contentDetailData}) => {
	const page = window.location.pathname.split("/")[1]
	const startDate = new Date(dateRange[0]).toDateString()
	const endDate = new Date(dateRange[1]).toDateString()
	const fileName = `MIR-${page}-${table}-${contentId}-${startDate}-${endDate}.pdf`

	return (
		<GridToolbarContainer sx={tableToolbarStyle.toolbarContainer}>

			{table == "BeforeAfter" ? 
						<Button paddingRight={1} startIcon={<SystemUpdateAltIcon />}
						onClick={()=>exportPDF(fileName, contentId, startDate, endDate, augmentedAnalyticsData, tableData, contentDetailData)}
					> 
						EXPORT .PDF
					</Button> :

				<GridToolbarExport
				csvOptions={{
					fileName: fileName,
				}}
				excelOptions={{
					fileName: fileName,
				}}
				/>
		}
			<GridToolbarColumnsButton/>
		</GridToolbarContainer>
	)
}

export const ColumnEditIcon = () => (<MoreVert/>)

export const columnLocalText = "ADD METRICS"