import React, {useEffect, useState} from 'react';
import {StateCard} from "../StateCard/StateCard";
import {LargeTableSkeleton} from "../../Loading/Skeleton/LargeTableSkeleton/LargeTableSkeleton";
import {emptyMessage, media, mediaTitle, platforms, platformTitle, title, topNApi} from "./TopNCardHelper";
import {PaperGrid} from "../../Foundational/PaperGrid/PaperGrid";
import {Grid, Typography} from "@mui/material";
import {Dropdown} from "../../Foundational/Dropdown/Dropdown";
import {TopNTable} from "../../Tables/TopNTable/TopNTable";
import {TopNCardStyle} from "./TopNCardStyle";
import PropTypes from "prop-types";

export const TopNCard = ({dateRange, department, departments, columnVisibilityModel, setColumnVisibilityModel, setEmpty, setHasError}) => {
	const [dataInfo, setDataInfo] = useState({loading: true, error: false, data: []})
	const [platformFilter, setPlatformFilter] = useState({id: "All Platforms", name: "All Platforms"})
	const [mediaFilter, setMediaFilter] = useState({id: "All Media", name: "All Content Types"})
	useEffect(() => topNApi(dateRange, department, departments, platformFilter, mediaFilter, setDataInfo, setEmpty, setHasError),
		[dateRange, platformFilter, mediaFilter, department, departments])


	if (dataInfo.loading || dataInfo.error) {
		return <StateCard loading={dataInfo.loading} error={dataInfo.error}
		                  component={<LargeTableSkeleton/>}
		                  message={emptyMessage}/>
	}

	return (
		<PaperGrid>
			<Grid container item
			      direction={"column"}
			      spacing={3}>
				<Grid item>
					<Typography sx={TopNCardStyle.title} variant={"h5"}>
						{title}
					</Typography>
				</Grid>
				<Grid container item direction={"row"} spacing={3}>
					<Grid item>
						<Dropdown
							onChange={(event, element) => {
								setPlatformFilter({id: element.props.value, name: element.props.children[1]})
							}}
							inputName={platformFilter.name}
							inputLabel={platformTitle}
							inputId={platformFilter.id}
							selectItems={platforms}
						/>
					</Grid>
					<Grid item>
						<Dropdown
							onChange={(event, element) => {
								setMediaFilter({id: element.props.value, name: element.props.children[1]})
							}}
							inputName={mediaFilter.name}
							inputLabel={mediaTitle}
							inputId={mediaFilter.id}
							selectItems={media}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<TopNTable topNData={dataInfo.data} columnVisibilityModel={columnVisibilityModel}
					           department={department} dateRange={dateRange}
					           setColumnVisibilityModel={setColumnVisibilityModel}/>
				</Grid>
			</Grid>
		</PaperGrid>
	)
}

TopNCard.propTypes = {
	dateRange: PropTypes.array.isRequired,
	department: PropTypes.shape({
		id: PropTypes.string.isRequired,
		parent: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}).isRequired,
	columnVisibilityModel: PropTypes.object.isRequired,
	setColumnVisibilityModel: PropTypes.func.isRequired,
	setEmpty:PropTypes.func.isRequired,
	setHasError:PropTypes.func.isRequired,
}