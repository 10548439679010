import moment from "moment";

export const isWithinRange = (startDate, endDate, range = 365) => {
	const start = moment(startDate)
	const end = moment(endDate)
	const diff = end.diff(start)

	return Math.round(moment.duration(diff).asDays()) <= range
}

export const getTime = (date = new Date(), day = false, hour = false, minute = false, second = false) => {
	let milliseconds, seconds, minutes, hours, days

	milliseconds = date.getTime()

	seconds = parseInt(Math.floor(milliseconds / 1000));
	if (second) return seconds

	minutes = parseInt(Math.floor(seconds / 60));
	if (minute) return minutes

	hours = parseInt(Math.floor(minutes / 60));
	if (hour) return hours

	days = parseInt(Math.floor(hours / 24));
	if (day) return days

	return milliseconds
}

export const getDateOfWeek = (week, year) => {
	let date = (1 + (week - 1) * 7); // 1st of January + 7 days for each week

	return new Date(year, 0, date);
}

export const getDepartments = (department, departments) => {

	const filters = {
		"All News including Regions": function newsAndRegionFilter(element) {
			return element.parent !== null
		},
		"All Regions": function regionFilter(element) {
			return element.parent === department.name
		},
	}
	const departmentFilter = (element) => element.name === department.name

	let departmentData = departments //JSON.parse(sessionStorage.getItem('departmentList'))
	let departmentList

	departmentList = departmentData.filter(filters[department.name] ?? departmentFilter)
		.map(objects => {
			return objects.id
		})

	return departmentList
}

export const groupByKey = (list, key) => list.reduce((hash, obj) => ({
	...hash, [obj[key]]:
		(hash[obj[key]] || []).concat(obj)
}), {})