export const renderCategoryStringStyle = {
	string: {
		typography: "text.body2",
	},
    stringContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		height: "100%",
		position: "relative",
        minWidth:"100px"
	},
}