import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import {
	aggregationLevel,
	dayDifference,
	getAggregationLevel,
	getFormattedDateString,
	getShortFormattedDateString
} from "../../../common/formatters";
import PropTypes from "prop-types";

export const LineGraph = ({data, groups, colourPalette, isEmpty}) => {

	const SVG = useRef(null);

	useEffect(() => {

		// set the dimensions and margins of the graph
		const margin = {top: 50, right: 20, bottom: 50, left: 100},
			width = 1000 - margin.left - margin.right,
			height = 250 - margin.top - margin.bottom;

		d3.select(SVG.current).selectAll("*").remove()

		const svg = d3.select(SVG.current)
			.attr("preserveAspectRatio", "xMinYMin meet")
			.attr("viewBox", [0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom])
			.append("g")
			.attr("transform", `translate(${margin.left}, ${margin.top})`)

        const x = d3.scaleBand()
            .domain(data.map(d => new Date(d.dateTime)))
            .range([20, width-20])
            .padding(0.1)

		let start, end, dayDiff, aggregationInterval, ticksFormat, ticksValues;
		[start, end] = d3.extent(data, d => new Date(d.dateTime));
		dayDiff = dayDifference(start, end)
		aggregationInterval = getAggregationLevel(start, end)

		// tick logic / formatting based off day difference
		ticksFormat = dayDiff < 1 ? d3.timeFormat("%I %p") : d3.timeFormat("%a, %b.%d")

        ticksValues =  x.domain().filter((d, i) => {
            if (aggregationInterval === aggregationLevel.HOURLY) {
                return !(i % 3);
            } else if (aggregationInterval === aggregationLevel.DAILY) {
                return dayDiff < 10 ? !(i % 1) : dayDiff < 20 ? !(i % 2) : !(i % 7);
            } else {
                return data.length < 10 ? !(i % 1) : data.length < 20 ? !(i % 2) : !(i % 7);
            }
        });

		// add the x Axis
		svg.append("g")
			.attr("class", "x axis")
			.style("font", "10px Roboto Mono")
			.attr("transform", `translate(0, ${height})`)
			.call(d3.axisBottom(x).tickValues(ticksValues).tickFormat(d3.timeFormat(ticksFormat)))

		// set the y ranges
		const overallMax =  isEmpty ? 150000 : d3.max(data, (d) => d["Average Time Spent"]);
		const y = d3.scaleLinear()
			.domain([0, overallMax]).nice()
			.range([height, 0])

		// add the y Axis
		svg.append("g")
			.attr("class", "y axis")
			.style("font", "10px Roboto Mono")
			.call(d3.axisLeft(y).ticks(5).tickSize(0))
			.call((g) => g.select(".domain").remove())
			.call((g) => g.selectAll(".tick line").clone()
				.attr("x2", width)
				.attr("stroke-opacity", 0.1))

		// text label for the y axis
		svg.append("text")
			.style("font", "10px Open Sans")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - margin.left)
			.attr("x", 0 - (height / 2))
			.attr("dy", "1em")
			.style("text-anchor", "middle")
			.text(`${groups[0]}`);

		if (!isEmpty){
		svg.append("path")
			.datum(data)
			.attr("transform", "translate(15, 0)")
			.attr("fill", "none")
			.attr("stroke", colourPalette[0])
			.attr("stroke-width", 2)
			.attr("d", d3.line()
				.x((d) => x(new Date(d.dateTime)))
				.y((d) => y(d["Average Time Spent"]))
			)
		}
		let svgNode = d3.select(SVG.current).node(),
			tooltip = d3.select(svgNode.parentNode)
				.append("div")
				.style("opacity", 0)
				.attr("class", "tooltip")
				.style("background-color", "white")
				.style("position", "absolute")
				.style("border", "solid #F2F2F2")
				.style("border-width", "1px")
				.style("border-radius", "4px")
				.style("padding", "10px")
				.style("box-shadow", "1px 2px 2px #F2F2F2")

		const onMouseMove = (event) => {
			let [xPos, yPos] = d3.pointer(event);
			let index = Math.floor((xPos - 35) / x.step());
			let dataPoint = data[index]
			if (!dataPoint) return

			let date = dayDiff < 1 ? getFormattedDateString(dataPoint.dateTime) :
				getShortFormattedDateString(dataPoint.dateTime)
			let size = 5

			tooltip
				.html(date + "<br>")
				.style("font-size", "14px")
				.style("opacity", 1)
				.style("color", "black")
				.style("font-weight", "600");

			let legend = tooltip
				.append("svg")
				.style("padding", "2px")
				.style("height", "25px")
				.style("width", "200px")

			legend
				.append("rect")
				.attr("y", 10)
				.attr("width", size)
				.attr("height", size)
				.attr("fill", colourPalette[0])

			// Add label and values for each marker.
			legend
				.append("text")
				.attr("x", 10)
				.attr("y", 17.5)
				.text("Average Time Spent: ")
				.style("font-family", "Open Sans, sans-serif")
				.style("font-size", "14px")
				.style("font-weight", "500")
				.append("tspan")
				.text(dataPoint["Average Time Spent"])
				.style("font-family", "Open Sans, sans-serif")
				.style("font-weight", "600")
				.style("font-size", "14px")

			tooltip.style("transform", "translateY(-55%)")
				.style("left", `${event.pageX - 250}px`)
				.style("top", `${event.pageY - 50}px`)
		}

		const onMouseLeave = (event) => {
			tooltip.style("opacity", 0)
		}

		svg.append("rect")
			.attr("width", width)
			.attr("height", height)
			.attr("fill", "transparent")
			.on("mousemove", onMouseMove)
			.on("mouseleave", onMouseLeave);

	}, [data, groups, colourPalette]);

	return <svg ref={SVG}/>;
};

LineGraph.propTypes = {
	data: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	colourPalette: PropTypes.array.isRequired
}