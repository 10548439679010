export const graphTabsStyle = {
	paper: {
		backgroundColor: "grey.50",
		width: "100%",
		height: "100%",
		margin: "auto",
		padding: 1
	},
	tab: {
		typography: "text.body1",
		"&.Mui-selected": {
			color: "primaryColour.brand"
		}

	},
	tabIndicator: {
		backgroundColor: "primaryColour.brand",
	},
	grid: {
		width: "100%",
		position: "relative", 
		overflow: "hidden"
	},
	kpiTitle: {
		typography: "text.body2",
		fontWeight: 400
	},
	kpiLegend: {
		width: 14,
		height: 15,
		borderRadius: 3
	},
	labels: {
		paddingTop: 1
	},
}