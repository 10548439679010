import ReactDOM from "react-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { BeforeAfterPDF } from "../../Cards/ExportPDFCard/BeforeAfterPDF/BeforeAfterPDF"

export const exportPDF = (pdfTitle, contentId, startDate, endDate, augmentedAnalyticsData, tableData, contentDetailData) => {
    const domNode = document.createElement("div");
    domNode.style.position = "absolute";
    domNode.style.left = "-9999px"; 
    domNode.style.width = "2500px"; 
    document.body.appendChild(domNode);
    ReactDOM.render(<BeforeAfterPDF contentId={contentId} startDate={startDate} endDate={endDate} augmentedAnalyticsData={augmentedAnalyticsData} tableData= {tableData} contentDetailData={contentDetailData}/>, domNode);

    setTimeout(() => {
        html2canvas(domNode, {
            scale: 2,
            width: domNode.scrollWidth, 
            height: domNode.scrollHeight, 
        }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", "a4");
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save(pdfTitle)

        ReactDOM.unmountComponentAtNode(domNode); 
        document.body.removeChild(domNode); 
    });
    }, 1000);
}